import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/TgytzcKB4OI">
    <SEO title="This is Us - Romans: Principles" />
  </Layout>
)

export default SermonPost
